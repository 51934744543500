import React, { useContext, useEffect, useState } from "react";
import { graphql } from "gatsby";
import { shuffle } from "lodash";
import Layout from "../layout";
import Meta from "../../components/content/Meta";
import { HeaderContext } from "../../context/HeaderContext";
import { SiteSettingsContext } from "../../context/SiteSettingsContext";
import TeamMemberBio from "../../components/content/TeamMemberBio";
import TeamSlider from "../../components/content/TeamSlider";

export const query = graphql`
  query TeamPageQuery($contentful_id: String!) {
    member: contentfulTeamMember(contentful_id: { eq: $contentful_id }) {
      id
      internal {
        type
      }
      name
      position
      credentials {
        credentials
      }
      image {
        fluid(quality: 80, maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      bio {
        json
      }
    }
    members: allContentfulTeamMember(filter: { slug: { nin: "dummy" } }) {
      edges {
        node {
          contentful_id
          name
          position
          credentials {
            credentials
          }
          slug
          image {
            fluid(quality: 80, maxWidth: 1000) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

const TeamPage = ({ data, pathContext, ...rest }) => {
  const { setPageTheme, setHeaderVisible } = useContext(HeaderContext);
  const { setSettings } = useContext(SiteSettingsContext);
  const [shuffledMembers, setShuffledMembers] = useState([]);

  if (!data || !data.member) return null;

  const { member, members } = data;

  useEffect(() => {
    if (pathContext.settings) {
      setSettings(pathContext.settings);
    }

    setPageTheme("dark");
    setHeaderVisible(true);
    setShuffledMembers(shuffle(members.edges).slice(0, 6));
  }, [pathContext]);

  return (
    <Layout pathContext={pathContext} {...rest}>
      <Meta
        defaults={pathContext.settings.defaultPageMetadata}
        meta={{
          title: `${member.name} - Meet the Team - Raindrum`,
          description: member.bio,
          ogTitle: `${member.name} - Meet the Team - Raindrum`,
          ogDescription: member.bio,
        }}
      />
      <TeamMemberBio
        name={member.name}
        slug={member.slug}
        position={member.position}
        credentials={member.credentials}
        image={member.image}
        bio={member.bio}
        pathContext={pathContext}
      />
      {shuffledMembers && (
        <TeamSlider pathContext={pathContext} members={shuffledMembers} />
      )}
    </Layout>
  );
};

export default TeamPage;
