import React from "react";
import Slider from "../../common/Slider";
import S from "./styles";
import { ContentfulTeamSlider, PathContext } from "../../../interfaces";

interface TeamSliderProps extends ContentfulTeamSlider {
  pathContext: PathContext;
}

const TeamSlider = ({
  members,
  pathContext: { settings },
}: TeamSliderProps) => {
  if (!members) return null;

  const breakPoints = [
    { width: 1, itemsToShow: 1.2 },
    { width: 750, itemsToShow: 2.5 },
    { width: 1000, itemsToShow: 3.5 },
  ];

  return (
    <Slider title="More of our team" carouselSettings={{ breakPoints }}>
      {members.map((member, index) => (
        <S.TeamMember
          key={index}
          cover
          direction="right"
          bg="white"
          to={`${settings.teamPageSlug}/${member.node.slug}`}
        >
          {member.node.image && <img srcSet={member.node.image.fluid.srcSet} />}
          {member.node.name && <h3>{member.node.name}</h3>}
          {member.node.position && <h4>{member.node.position}</h4>}
          {member.node.credentials && (
            <h4>{member.node.credentials.credentials}</h4>
          )}
        </S.TeamMember>
      ))}
    </Slider>
  );
};

export default TeamSlider;
