import styled from "styled-components";

const TeamMemberBio = styled.section`
  position: relative;
  padding: 200px 0 100px 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: 120px 0 60px 0;
  }

  .back-to-team {
    position: relative;
    margin-bottom: 55px;
    padding-left: 20px;

    a {
      text-decoration: none;
      font-size: 22px;
      line-height: 32px;
      border-bottom: 2px solid ${({ theme }) => theme.colors.darkGrey};
      font-family: ${({ theme }) => theme.fonts.ttNormsBold};
      padding-bottom: 5px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.darkGrey};
        font-family: ${({ theme }) => theme.fonts.ttNormsRegular};
        font-size: 16px;
        line-height: 26px;
      }

      &:before {
        position: absolute;
        left: 0;
        content: "<";
      }
    }
  }

  .team-member {
    display: flex;

    @media screen and (max-width: 750px) {
      flex-direction: column;
    }

    .image {
      flex: 5;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        margin: 0 -20px;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    .details {
      flex: 7;
      padding: 0 0 0 70px;

      @media screen and (max-width: 750px) {
        padding: 0;
      }

      h3 {
        font-family: ${({ theme }) => theme.fonts.butlerMedium};
        font-size: 35px;
        line-height: 40px;
        font-weight: normal;
        margin-top: 100px;
      }

      .position {
        font-family: ${({ theme }) => theme.fonts.ttNormsMedium};
        font-size: 22px;
        line-height: 26px;
        padding-bottom: 10px;

        @media screen and (max-width: ${({ theme }) =>
            theme.breakpoints.small}) {
          width: 100%;
          font-size: 17px;
        }
      }

      .position-2 {
        font-family: ${({ theme }) => theme.fonts.ttNormsMedium};
        font-size: 18px;
        line-height: 22px;
        padding-bottom: 20px;

        @media screen and (max-width: ${({ theme }) =>
            theme.breakpoints.small}) {
          width: 100%;
          font-size: 16px;
        }
      }

      .bio {
        font-family: ${({ theme }) => theme.fonts.ttNormsRegular};
        font-size: 20px;
        line-height: 28px;

        @media screen and (max-width: ${({ theme }) =>
            theme.breakpoints.small}) {
          font-size: 18px;
          line-height: 28px;
        }
      }
    }
  }
`;

export default {
  TeamMemberBio,
};
