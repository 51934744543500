import React, { useContext } from "react";
import { graphql, Link } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Img from "gatsby-image";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Container from "../../common/Container";
import { contentfulRichTextLinks } from "../../../utils/helpers";
import S from "./styles";
import { SiteSettingsContext } from "../../../context/SiteSettingsContext";
import { ContentfulTeamMember, PathContext } from "../../../interfaces";

interface TeamMemberBioProps extends ContentfulTeamMember {
  pathContext: any;
}

const TeamMemberBio = ({
  name,
  position,
  credentials,
  image,
  bio,
  pathContext,
}: TeamMemberBioProps) => {
  const { pageMap } = pathContext;
  const { settings } = useContext(SiteSettingsContext);

  return (
    <S.TeamMemberBio>
      <Container>
        {settings.teamPageSlug && (
          <div className="back-to-team">
            <AniLink
              cover
              direction="right"
              bg="#D8E1DD"
              to={settings.teamPageSlug}
            >
              Back to our team
            </AniLink>
          </div>
        )}
        <div className="team-member">
          {image && (
            <div className="image">
              <Img fluid={image.fluid} alt={name} />
            </div>
          )}
          <div className="details">
            <h3>{name}</h3>
            {position && <div className="position">{position}</div>}
            {credentials && (
              <div className="position-2">{credentials.credentials}</div>
            )}
            <div className="bio">
              {documentToReactComponents(bio.json, {
                renderNode: {
                  ...contentfulRichTextLinks({ pageMap }),
                },
              })}
            </div>
          </div>
        </div>
      </Container>
    </S.TeamMemberBio>
  );
};

export default TeamMemberBio;
