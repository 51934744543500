import styled from "styled-components";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Carousel from "react-elastic-carousel";

const TeamSlider = styled.section`
  position: relative;
  padding-bottom: 100px;

  h3 {
    font-family: ${({ theme }) => theme.fonts.ttNormsBold};
    font-size: 22px;
    font-weight: normal;
    line-height: 32px;
    padding-bottom: 12px;
    border-bottom: 1.5px solid ${({ theme }) => theme.colors.darkGrey};
    margin: 0;
  }

  .navigation {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;

    button {
      background-color: transparent;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      outline: none;
      padding-left: 30px;
      cursor: pointer;
    }
  }
`;

const CarouselWrapper = styled(Carousel)`
  .rec-slider-container {
    margin: 0;

    .rec-slider {
      > div {
        &:last-child {
          .rec-item-wrapper {
            padding-right: 0 !important;
          }
        }
      }
    }
  }
`;

const TeamMember = styled(AniLink)`
  position: relative;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  padding: 0 25px 0 0;

  img {
    position: relative;
    object-fit: cover;
    max-width: 100%;
    width: 100%;
    height: 400px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      height: 340px;
    }
  }

  h3 {
    font-family: ${({ theme }) => theme.fonts.butlerMedium};
    font-weight: normal;
    font-size: 35px;
    line-height: 40px;
    margin: 20px 0 0 0;
    border-bottom: none;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      font-size: 30px;
      line-height: 34px;
    }
  }

  h4 {
    font-family: ${({ theme }) => theme.fonts.ttNormsRegular};
    font-weight: normal;
    font-size: 22px;
    line-height: 36px;
    margin: 0;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      font-size: 18px;
      line-height: 30px;
    }
  }
`;

export default {
  CarouselWrapper,
  TeamSlider,
  TeamMember,
};
